import React from 'react';
import PropTypes from 'prop-types';
import { Fab, Tooltip, Zoom } from '@mui/material';

const Submenu = props => {
    const { visible, timeout, title, onClick, active, comp } = props;

    return <Zoom in={visible} timeout={timeout}>
        <Tooltip title={title} placement="left">
            <Fab onClick={onClick} color={active ? "secondary" : null} aria-label={title} size="small">
                {comp}
            </Fab>
        </Tooltip>
    </Zoom>;
}

export default Submenu;

Submenu.propTypes = {
    visible: PropTypes.bool.isRequired,
    timeout: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    comp: PropTypes.object.isRequired,
};
