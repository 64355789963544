import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, ButtonBase } from '@mui/material';

const Tile = props => {
    const { data, play, focus } = props;

    return <Card
        sx={{
            width: { xs: '100%', sm: '49%', md: '24%' },
            borderRadius: 2,
            margin: '0.5%',
            opacity: focus ? 1.0 : 0.2,
        }}
    >
        <ButtonBase onMouseDown={() => play(data.soundUrl, data.name)}>
            <CardMedia
                component="img"
                image={data.imageUrl}
                alt={data.name}
            />
        </ButtonBase>
    </Card>;
}

export default Tile;

Tile.propTypes = {
    data: PropTypes.object.isRequired,
    play: PropTypes.func.isRequired,
};
