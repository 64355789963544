import { useState, useEffect } from "react";

const useAudio = () => {
    const [audio, setAudio] = useState(new Audio());
    const [playing, setPlaying] = useState(false);
    const [playingUrl, setPlayingUrl] = useState(null);

    const play = soundUrl => {
        if (playing) {
            audio.pause();
        }

        let newAudio = new Audio(soundUrl);
        setAudio(newAudio)
        newAudio.play();

        setPlaying(true);
        setPlayingUrl(soundUrl);
    };

    const stop = () => {
        if (playing) {
            audio.pause();
            setPlaying(false);
        }
    };

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);

    return [playing, play, stop, playingUrl];
};

export default useAudio;
