import './App.css';

import TilesView from './views/TilesView/TilesView';

function App() {
    return (
        <div>
            <TilesView />
        </div>
    );
}

export default App;
