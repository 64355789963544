import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Fab, Stack } from '@mui/material';
import { Close, Flight, Nature, Park, Pause, Pets, PlayArrow, Reorder } from '@mui/icons-material';

import Tile from '../../components/Tiles/Tile';
import Submenu from './Submenu';
import useAudio from '../../utils/useAudio';

import BirdSounds from './Sounds/BirdSounds.json';
import FarmSounds from './Sounds/FarmSounds.json';
import ForestSounds from './Sounds/ForestSounds.json';
import JungleSounds from './Sounds/JungleSounds.json';

const TilesView = () => {
    const [playing, play, stop] = useAudio();
    const [showMenu, setShowMenu] = useState(false);
    const [soundSet, setSoundSet] = useState('farm');

    const [playingUrl, setPlayingUrl] = useState();
    const [playingName, setPlayingName] = useState();
    const [playingAllIndex, setPlayingAllIndex] = useState(null);

    const sounds = {
        'bird': BirdSounds,
        'farm': FarmSounds,
        'forest': ForestSounds,
        'jungle': JungleSounds,
    };

    const activateSound = setName => {
        setSoundSet(setName);
        setShowMenu(false);
        setPlayingAllIndex(null);
        stop();
    }

    const playSound = (url, name) => {
        play(url);
        setPlayingUrl(url);
        setPlayingName(name);
        setPlayingAllIndex(null);
    };

    const stopSound = () => {
        setPlayingAllIndex(null);
        stop();
    }

    const playAll = i => {
        let animal = sounds[soundSet][i];
        playSound(animal.soundUrl, animal.name);
        setPlayingAllIndex(i);
    };

    useEffect(() => {
        if (!playing && playingAllIndex !== null && sounds[soundSet].length > playingAllIndex + 1) {
            playAll(playingAllIndex + 1);
        }
    }, [playing, playingUrl]);

    return <Box
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: '0.5%',
        }}>
        {
            sounds[soundSet].map((e, i) => <Tile key={i} play={playSound} focus={!playing || playingUrl === e.soundUrl} data={e} />)
        }

        <Stack sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'fixed',
            right: '85px',
            bottom: '22px',
        }}>
            <Box sx={{ margin: 'auto 20px' }}>
                {playing && playingName}
            </Box>
            <Submenu onClick={playing ? stopSound : () => playAll(0)} visible={true} timeout={0} title={playing ? "Stop" : "Alle Tiere nacheinander abspielen"} comp={(
                <>
                    {playing && <Pause fontSize="small" />}
                    {!playing && <PlayArrow fontSize="small" />}
                </>
            )} />
        </Stack>

        <Stack spacing={2} sx={{
            position: 'fixed',
            right: '8px',
            bottom: '15px',
        }}>
            <Submenu onClick={() => activateSound('farm')} visible={showMenu} timeout={350} title="Farm" active={soundSet === 'farm'} comp={(
                <Pets fontSize="small" />
            )} />
            <Submenu onClick={() => activateSound('forest')} visible={showMenu} timeout={300} title="Wald" active={soundSet === 'forest'} comp={(
                <Park fontSize="small" />
            )} />
            <Submenu onClick={() => activateSound('bird')} visible={showMenu} timeout={250} title="Vögel" active={soundSet === 'bird'} comp={(
                <Flight fontSize="small" />
            )} />
            <Submenu onClick={() => activateSound('jungle')} visible={showMenu} timeout={200} title="Jungle" active={soundSet === 'jungle'} comp={(
                <Nature fontSize="small" />
            )} />
            <Fab color="primary" onClick={() => setShowMenu(!showMenu)} aria-label="add" sx={{
                left: '-7px',
            }}>
                {showMenu && <Close />}
                {!showMenu && <Reorder />}
            </Fab>
        </Stack>
    </Box >;
}

export default TilesView;
